import React from 'react'
import { Grid } from '@mui/material'
import head1 from './lnd/ahead1.png'
import head2 from './lnd/head2.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1600
});

function Dex() {
  return (
    <div className='bgsts'> 

    <div className='container'>
    <br/>
<br/>
<div className='bgca'>
<p>CA : AuYzLSUHCKiX4MTK21J2vDAqj6JX5Wa6BPSwugw7CoJA</p>

</div><br/><br/>
<Grid container spacing={2}>
<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>
<div data-aos="zoom-in-down">   <img src={head1}  style={{width:"100%"}}/></div>

   </Grid>
   <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>
   <div data-aos="zoom-in-down"> <p className='titlepl'>Welcome to the world of Long Nose Dog Coin, where 
      innovation meets humor on the blockchain! Our journey began
       with a simple yet profound motto: "Let me do it for you." 
       Embracing the meme culture that brings joy and laughter, we've created 
       a unique space on the Solana blockchain that combines cutting-edge 
      technology with a dash of canine hilarity.</p></div>
  
   </Grid>
</Grid>

    </div>
    <br/>  <br/>
    <div className='container'>
<Grid container spacing={2}>
<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>
<div data-aos="zoom-in-down"><img src={head2}  style={{width:"100%"}}/></div>
   
   </Grid>
   <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>
   <div data-aos="zoom-in-down"><p className='titlepl'>The long nose is not just a feature; 
   it's a symbol of our commitment to reaching new heights in the crypto world. 
   With determination and a touch of playfulness, Long Nose Dog Coin  is here to navigate
    the digital landscape and
    make blockchain technology more accessible to everyone.</p></div>
   
   </Grid>
</Grid>

    </div>
    
    
   </div>
 
  )
}

export default Dex