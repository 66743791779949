import React from 'react'
import { Grid } from '@mui/material'
import Link from '@mui/material/Link';
import Logo from './lnd/logo.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});
function Navbar() {
  return (
    <div className='navbartop'>
      <br/>
       <div className='container'>
        <Grid container spacing={2}>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
               <div className='centeritall ' >
               <div data-aos="fade-right">  
               <Link href='https://birdeye.so/token/AuYzLSUHCKiX4MTK21J2vDAqj6JX5Wa6BPSwugw7CoJA/HwWsb4XoSsNzG5vVYR28JJ9cfURmjenpFtUiqepFLJ4C?chain=solana'> <button>
  <span> CHART
  </span>
</button></Link>
                 </div>
                
           
               </div>
               </Grid>
              
             
               <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
               <div className='centeritall ' >
               <div className="item" data-aos="fade-up">  <img src={Logo} style={{width:"100%"}}/></div>
               
               </div>
               </Grid>
               <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
               <div className='centeritall ' >
               <div data-aos="fade-left"> 
               <Link href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=AuYzLSUHCKiX4MTK21J2vDAqj6JX5Wa6BPSwugw7CoJA&fixed=out'> <button>
  <span> Buy $Long
  </span>
</button></Link>
              </div>
               
               </div>
               </Grid>

        
             
                             </Grid></div>
                             <br/>
      
      
      </div>
  )
}

export default Navbar