import React, { useState } from "react";
import musicFile from "./long.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Desktop from "./Desktop";
import DesktopM from "./DeskMob";
import MEME from "./Desktopthree";
import Dex from "./Dex";
import { Grid } from '@mui/material'
import Video from './Gib'
import Footer from './Footer'
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

import Logo from './lnd/doit.png'
import Logom from './lnd/mdo.png'
import Logos from './lnd/longs.png'

import music from './long.mp3'

const Home = () => {
  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };

  const { width, height } = useWindowSize();

  return (
    <div className="mainContent">
      <div className="garden">
        {!smallScreen && (
          <div className="desk">
         
            <Confetti width={width} height={height} recycle={false} />
            <Desktop />
            <br/><br/>
            <Dex />
            <br/><br/> 
            <Video />
            <br/><br/> 
            <MEME />
            <Footer />


            <> 
        <Modal
          backdrop="static"
          show={showModal}
          centered
          dialogClassName="modal-fullscreen"  // Custom class for full-screen modal
          keyboard={false}
          onHide={() => {}}
        >
<Confetti width={width} height={height} recycle={false} />
<div className='navbartops'>
   <Grid container spacing={2}>
   <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

<img src={Logos} className='startlogo'/>

</Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

       <img src={Logo} className='startlogo'/>

       </Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'> 
        <a onClick={() => {
                      handleYesClick();
                      setShowModal(false);
                    }}><h1 className='enterlnd' >Let me do it for you</h1></a>    
               </Grid>


</Grid>
 </Grid>
        <br/> <br/>
    </div>
         
        </Modal>
      </>
          </div>
        )}

        {smallScreen && (
          <div className="mob">
         <Confetti width={width} height={height} recycle={false} />
            <DesktopM />
            <br/><br/>
            <Dex />
            <br/><br/> 
            <Video />
            <br/><br/> 
            <MEME />
            <Footer />
            <> 
        <Modal
          backdrop="static"
          show={showModal}
          centered
          dialogClassName="modal-fullscreen"  // Custom class for full-screen modal
          keyboard={false}
          onHide={() => {}}
        >
<Confetti width={width} height={height} recycle={false} />
<div className='navbartops'>
   <Grid container spacing={2}>
   <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
   <br/> <br/>   <br/> <br/>   <br/> <br/>
<img src={Logos} className='startlogo'/>

</Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

       <img src={Logom} className='startlogo'/>

       </Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>   <br/> <br/>   <br/> <br/> 
        <a onClick={() => {
                      handleYesClick();
                      setShowModal(false);
                    }}><h1 className='enterlnd'>Let me do it for you</h1></a>    
               </Grid>


</Grid>
 </Grid>
 <br/> <br/>   <br/> <br/><br/> <br/> <br/> <br/> <br/> <br/> 
    </div>
         
        </Modal>
      </>
          </div>
        )}
      </div>

    
    </div>
  );
};

export default Home;
