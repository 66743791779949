import React from 'react'
import Navbar from './Navbar'
import { Grid } from '@mui/material'
import Logo from './lnd/let.png'
import Link from '@mui/material/Link';
import Logo1 from './lnd/h1.png'
import Logo2 from './lnd/h2.png'
import AOS from "aos";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});

function Desktop() {
  const { width, height } = useWindowSize();
  return (
    <div className='backofdesktop'>
        <Confetti width={width} height={height} recycle={false} />   <Confetti width={width} height={height} recycle={false} />   <Confetti  width={width} height={height} recycle={false} />
       <Navbar/>

       <Grid container spacing={2}>
       <Grid item md={4} xs={12} lg={4} sm={12} > 
               <div className='centeritall ' ><br/> <br/> <br/><br/> <br/> <br/>
               <img src={Logo1} style={{width:"100%"}}/>
               </div>
              
             
             </Grid>


             <Grid item md={4} xs={12} lg={4} sm={12} > 
               <div className='centeritall ' ><br/> <br/> <br/><br/> <br/> <br/>
               <div data-aos="zoom-in"> <img src={Logo} style={{width:"100%"}}/></div>
          
               </div>
               <div className='centeritall ' ><br/> <br/>
               <Link href='https://twitter.com/LNDsolana'><button>
  <span> Twitter
  </span>
</button></Link>
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
<Link href='https://t.me/LNDsolana'><button>
  <span> Telegram
  </span>
</button></Link>
<br/> <br/> <br/>
               </div>
             
             </Grid>



             <Grid item md={4} xs={12} lg={4} sm={12} > 
               <div className='centeritall ' ><br/> <br/> <br/><br/> <br/> <br/>
           <img src={Logo2} style={{width:"100%"}}/>
               </div>
             
             
             </Grid>

       </Grid>
      
       <br/><br/>
      <br/><br/>

     </div>
  )
}

export default Desktop