import React from 'react'
import { Grid } from '@mui/material'
import meme1 from './lnd/meme1.jpg'
import meme2 from './lnd/meme2.jpeg'
import meme3 from './lnd/meme3.jpeg'
import meme4 from './lnd/meme4.jpg'
import meme5 from './lnd/meme5.jpeg'
import meme6 from './lnd/meme6.jpeg'

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});

function Desktopthree() {
  return (
    <div className='container'>
          <Grid container spacing={2}>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>
       <div data-aos="zoom-out-up"> <img src={meme1} style={{width:"100%"}}/></div>
          

       </Grid>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>
       <div data-aos="zoom-out-up"></div>
<img src={meme2} style={{width:"100%"}}/>

</Grid>
<Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>
<div data-aos="zoom-out-up"><img src={meme3} style={{width:"100%"}}/></div>


</Grid>



<Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>
<div data-aos="zoom-out-up"><img src={meme4} style={{width:"100%"}}/></div>


</Grid>
<Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>
<div data-aos="zoom-out-up"><img src={meme5} style={{width:"100%"}}/></div>


</Grid>
<Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>
<div data-aos="zoom-out-up"><img src={meme6} style={{width:"100%"}}/></div>


</Grid>
  </Grid>

  
<br/>
    </div>
  )
}

export default Desktopthree