import React from 'react'
import Navbar from './NavbarM'
import { Grid } from '@mui/material'
import Logo from './lnd/let.png'
import Link from '@mui/material/Link';
function Desktop() {
  return (
    <div className='backofdesktop'>
       <Navbar/>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'> 
               <div className='centeritall ' ><br/> <br/> <br/><br/> <br/> <br/>
           <img src={Logo} style={{width:"100%"}}/><br/> <br/> <br/>
               </div>
             
             
             </Grid>
             <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'> 
             <Link href='https://twitter.com/LNDsolana'>      <button>
  <span> Twitter
  </span>
</button></Link>
       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
<Link href='https://t.me/LNDsolana'><button>
  <span> Telegram
  </span>
</button></Link>

             
             </Grid>
      <br/><br/>

     </div>
  )
}

export default Desktop