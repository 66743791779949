import React from 'react'
import Logo from './lnd/mdo.png'
import Logos from './lnd/longs.png'
import { Grid } from '@mui/material'
import music from './long.mp3'
function Start() {
  return (
    <div className='navbartopsm'>
   <Grid container spacing={2}>
   <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
   <br/> <br/>   <br/> <br/>   <br/> <br/>
<img src={Logos} className='startlogo'/>

</Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

       <img src={Logo} className='startlogo'/>

       </Grid>
       <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
       <Grid item md={4} xs={12} lg={4} sm={12} className='centeritall'>   <br/> <br/>   <br/> <br/> 
        <a href='/LoneNoseDog
        '><h1 className='enterlnd'>Let me do it for you</h1></a>    
               </Grid>


</Grid>
 </Grid>
 <br/> <br/>   <br/> <br/><br/> <br/> <br/> <br/> <br/> <br/> 
    </div>
  )
}

export default Start