import React from 'react';
import { Grid } from '@mui/material';
import dog from './dog.mp4';

function Gib() {
  return (
    <div className='navbartop'>
      <br/><br/><br/>
      <div className='container'>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>
            <div style={{ position: 'relative', paddingTop: '56.25%', width: '100%', border: '3px solid black' }}>
              <video
                src={dog}
                autoPlay
                loop
                muted  // Add this line to mute the video
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: '3px solid black' }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <br/><br/><br/>
    </div>
  );
}

export default Gib;
